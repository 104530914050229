.c-query {
    @extend %flexRow;
    gap: 20rem;
    padding: 60rem 0;
    justify-content: center;

    &__content {
        img {
            max-width: 100%;
        }
    }

    &__btns {
        flex-basis: 100%;
        @extend %flexRow;
        align-items: stretch;
        gap: 8rem;
    }
    &__btn {
        flex: 1;
        @extend %flexRow;
        gap: 6rem;
        justify-content: center;
        font-weight: $fw-regular;
        i {
            font-size: 18rem;
            color: $c-blue;
        }
        &.active {
            background: $c-blue;
            color: $c-white;
            i {
                color: $c-white;
            }
        }
    }

    &__viewer {
        flex-basis: 100%;
        max-width: 100%;
        width: 880rem;
        height: 475rem;
    }
}