.o-modal {
    @extend %flexRow;
    justify-content: center;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($c-black, .6);
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-out;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &--container {
        background: $c-white;
        flex-basis: 80vw;
        max-width: 700rem;
        max-height: 80vh;
        overflow: hidden;
        padding: 36rem 36rem 24rem;
        position: relative;
        border-radius: 8rem;

        .c-query__viewer {
            max-height: 55vh;
        }
        
    }

    &--close {
        position: absolute;
        right: 8rem;
        top: 8rem;
        cursor: pointer;
        i {
            font-size: 24rem;
        }
    }

    &--content {
        @extend %flexRow;
        justify-content: center;
        gap: 16rem;

        &__text {
            font-size: 20rem;
        }
    }
}