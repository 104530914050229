.c-error {
    @extend %flexRow;
    justify-content: center;
    gap: 12rem;
    padding: 40rem 20rem;

    &__icon {
        color: $c-title;
        font-size: 48rem;
    }

    &__text {
        flex-basis: 100%;
        text-align: center;
        font-size: 26rem;
        line-height: 1.5;
        font-weight: $fw-semiBold;
        color: $c-title;
        margin-bottom: 20rem;

        span {
            font-weight: $fw-regular;
            font-size: 22px;
        }
    }
}