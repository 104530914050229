.c-header {
    background: $c-white;
    position: relative;
    box-shadow: 0 0 6rem rgba($c-black, .25);
    z-index: 2;


    &--nav {
        height: 72rem;

        &__logo {
            font-size: 24rem;
            font-weight: $fw-light;
            color: $c-title;

            span {
                font-size: inherit;
                color: inherit;
                font-weight: $fw-bold;
            }

            &-login {
                font-size: 36rem;
            }
        }

        &__menu {
            flex: 1;
            @extend %flexRow;
            justify-content: flex-start;
            gap: 24rem;
            font-size: 16rem;
            line-height: 1;
            color: $c-text;

            &-link {
                text-decoration: none;

                &:hover {
                    opacity: 0.8;
                }

                .notify {
                    background: $c-red;
                    color: $c-white;
                    padding: 2rem 6rem;
                    border-radius: 15rem;
                    font-size: 11rem;
                    position: relative;
                    top: -8rem;
                    left: -2rem;
                }
            }
        }

        &__user {
            * {
                color: $c-text;
                font-size: 16rem;
                font-weight: $fw-regular;
                text-decoration: none;
            }

            &-link {
                @extend %flexRow;
                gap: 6rem;

                i {
                    font-size: 24rem;
                }
            }
        }

    }

    &--title {
        height: 116rem;
        background: $c-white;

        &__page {
            font-size: 36rem;
            font-weight: $fw-bold;
            flex-basis: 100%;
        }

        &__obs {
            font-size: 20rem;
            font-weight: $fw-regular;
            flex-basis: 100%;
            color: $c-text;
            margin-top: 8rem;
            line-height: 1.5;
        }
    }

    &--login {
        height: 220rem;
    }

    &--btns {
        @extend %flexRow;
        justify-content: flex-end;
        gap: 16rem;
    }
    
}