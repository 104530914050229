.c-footer {
    @extend %flexRow;
    justify-content: center;
    gap: 12rem;
    padding: 40rem 20rem;
    background: $c-white;

    &__text {
        flex-basis: 100%;
        text-align: center;
        font-size: 22rem;
        font-weight: $fw-semiBold;
        color: $c-title;
    }

    &__btn {
        max-width: fit-content;
        border-color: $c-red;
        color: $c-red;
        @extend %flexRow;
        gap: 10rem;
        padding: 8rem 16rem;
        line-height: 1;
        font-weight: $fw-regular;
        font-size: 16rem;

        i {
            color: $c-red;
        }

        &:hover {
            background: $c-red;
            i {
                color: $c-white;
            }
        }
    }

    &__icon {
        
    }
}