@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



@for $i from 1 through 12{
  .o-form--col-#{$i}{
    max-width: calc(100% / 12 * $i - 32rem) !important;
    flex-basis: calc(100% / 12 * $i - 32rem) !important;
  }
}

html {
    -ms-text-size-adjust: 100%;
    font-size: .8px;

    @media screen and (min-width: 1024px) {
        font-size: 1px;
    }
    
    @media screen and (min-width: 1600px) {
        font-size: 1.2px;
    }
}
html, body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    overflow: auto;
    width: 100%;
    overflow-x: hidden;
}

body {
    background: $c-bg;
}

* {
    font-family: Roboto, sans-serif;
    font-weight: $fw-regular;
    line-height: 1;
    font-size: 14rem;
    min-width: 0;
    color: $c-text;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #6a6a6a #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #6a6a6a;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

h1, h2, h3, h4, h5, h6 {
    color: $c-title;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    max-height: 100%;
}

::-webkit-input-placeholder { /* Edge */
    color: $c-placeholder;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $c-placeholder;
  }
  
  ::placeholder {
    color: $c-placeholder;
  }



%transition {
    transition: all 0.15s ease-in-out;
}

%flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

%flexRow {
    @extend %flex;
    flex-direction: row;
}

%flexColumn {
    @extend %flex;
    flex-direction: column;
}

.o-container {
    @extend %flexRow;
    padding: 0 20rem;
    gap: 20rem;
    max-width: 1180rem;
    margin: 0 auto;
    height: 100%;

    &.center {
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    &.justify-between {
        & > * {
            flex: 1 !important;
        }
    }
}

.o-heading {
    @extend %flexRow;
    gap: 4rem;
    flex-basis: 100%;
    
    &--title {
        font-size: 22rem;
        line-height: 1.5;
        color: $c-title;
        font-weight: $fw-bold;
    }

    &--text {
        font-size: 18rem;
        line-height: 1.5;
        color: $c-text;
        flex: 1;
        flex-basis: 100%;
    }

    &--cta {
        @extend %flexRow;
        gap: 8rem;
        flex: 1;
        flex-basis: fit-content;
        justify-content: flex-end;

        &__item {
            flex-basis: fit-content;

            &-input {
                flex-basis: 280rem;
            }
            
            input {
                margin-top: 0;
                width: 100%;
            }
        }
    }
}

.o-cards {
    @extend %flexRow;
    gap: 30rem;
    align-items: stretch;
}

.o-card {
    flex: 1;
    @extend %flexRow;
    gap: 20rem;
    background: $c-white;
    border-radius: 8rem;
    box-shadow: 0 0 6px rgba($c-black, .25);
    padding: 24rem;
    min-width: 300rem;
    max-width: 100%;

    &--grid {
        padding: 0;
        overflow-x: auto;
        gap: 0;
    }
}

.o-form {
    @extend %flexRow;

    &--group {
        @extend %flexRow;
        justify-content: flex-start;
        gap: 0;
        padding: 8rem 16rem;
        flex-basis: 100%;

        &-btns {
            justify-content: center;
            gap: 16rem;

            & > * {
                flex-basis: fit-content;
            }
        }

        &-login_cta {
            margin-top: 8rem;
            justify-content: center;
            gap: 16rem;

            & > * {
                flex-basis: 100%;
            }

            a {
                color: $c-blue;
                font-weight: $fw-semiBold;
                font-size: 16rem;
            }
        }

        &__upload {
            position: relative;
        }

        &__attachment {
            gap: 8rem;
        }
    }

    &--input {
        margin-top: 4rem;
        background: $c-white;
        border: 1rem solid $c-lightblue;
        border-radius: 8rem;
        padding: 8rem 16rem;
        flex-basis: 100%;
        outline: 0;

        &-group {
            @extend %flexRow;
            flex: 1;
            flex-basis: 100%;
            gap: 8rem;

            &--inline > * {
                flex: 1 !important;
            }
        }

        &:disabled {
            background: $c-bg;
            color: $c-placeholder;
        }

        &__textarea {
            &-mail {
                height: 180rem;
            }
        }

        &__search {
            padding-left: 38rem;
            background-image: url( '../img/sbg-sprite/search.svg' );
            background-size: 18rem;
            background-position: 12rem center;
            background-repeat: no-repeat;
        }

        &:focus {
            border-color: $c-blue;
        }
    }

    &--checkbox {
        @include checkbox();
    }

    &--label {
        line-height: 1.5;
        @extend %flexRow;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10rem;
        font-weight: $fw-semiBold;
        flex: 1;
        flex-basis: 100%;

        &__term {
            font-size: 12rem;
        }

        span {
            flex: 1;
            font-weight: $fw-regular;
            text-align: left;

            a {
                font-weight: $fw-semiBold;
                color: $c-blue;
            }
        }
    }

    &--upload {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        cursor: pointer;

        &:hover + .o-btn {
            background: $c-blue;
            color: $c-white;
            
        }
    }

    &--attachments {
        @extend .o-form--upload;

        &:disabled,
        &[disabled] {
            cursor: no-drop;
        }

        & + .o-btn {
            @extend %flexRow;
            flex: initial;
            gap: 4rem;
            
            i {
                color: $c-blue;
                font-size: 16rem;
            }
        }

        &:hover + .o-btn {
            background: $c-blue;
            color: $c-white;

            i {
                color: $c-white;
            }
            
        }
    }

    &--value {
        line-height: 1.5;
    }
}

.o-upload-files {
    flex-basis: 100%;

    .c-content--files__form-row {
        .close {
            cursor: pointer;
        }

        &:hover {
            background: rgba($c-bg-lightblue, .6);
        }
    }
}

.o-btn {
    font-size: 14rem;
    color: $c-blue;
    border: 1rem solid $c-blue;
    border-radius: 8rem;
    font-weight: $fw-bold;
    padding: 12rem 16rem;
    background: transparent;
    @extend %transition;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:hover {
        background: $c-blue;
        color: $c-white;
    }

    &--full {
        flex-basis: 100%;
    }
    &--fill {
        background: $c-blue;
        color: $c-white;

        &:hover {
            background: rgba($c-blue, .8);
        }
    }

    &-small {
        font-weight: $fw-regular;
        font-size: 12rem;
        padding: 8rem 12rem;
    }
    
    &-icon {
        @extend %flexRow;
        justify-content: flex-start;
        gap: 8rem;
        flex-basis: fit-content;
        max-width: fit-content;
        
        i {
            font-size: 17rem;
            color: inherit;
        }
    }

    &:disabled,
    &[disabled] {
        background: rgba($c-blue, .6) !important;
        color: $c-white !important;

        i {
            color: $c-white !important;
        }
    }
}

.o-load {
    display: none;

    &.loading {
        position: fixed;
        @extend %flexRow;
        justify-content: center;
        align-content: center;
        gap: 15rem;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        background-color: rgba($c-white, .75);

        span {
            flex-basis: 100%;
            text-align: center;
            color: $c-title;
            font-size: 16rem;
            font-weight: $fw-semiBold;
        }
    }
}

.o-dropdown {
    &-list {
        display: none;

        &.active {
            min-width: 140rem;
            max-width: 240rem;
            @extend %flexColumn;
            align-content: flex-start;
            gap: 2rem;
            position: absolute;
            background: $c-white;
            padding: 8rem 0;
            border-radius: 8rem;
            box-shadow: 0 0 6rem rgba($c-black, .25);

            li {
                flex: 1;
                flex-basis: 100%;
                width: 100%;
                @extend %flexRow;

                a {
                    padding: 8rem 16rem;
                    font-size: 12rem;
                    line-height: 1;
                    font-weight: $fw-semiBold;
                    color: $c-text;
                    flex: 1;
                    text-decoration: none;
                }

                &:hover {
                    background: $c-bg-blue;
                }
            }
        }
    }
}