.c-content {
    @extend %flexRow;
    gap: 40rem;
    padding: 60rem 0;

    & > * {
        flex-basis: 100%;
        @extend %flexRow;
        gap: 20rem;
    }

    &--login {
        max-width: 432rem;
    }
    
    &--warning {
        background: $c-bg-blue;
        @extend %flexRow;
        justify-content: center !important;
        gap: 10rem;
        padding: 16rem;
        border-radius: 8rem;
        border: 1rem solid $c-text-blue;

        &.inactive {
            display: none;
        }
        
        &__title {
            flex-basis: 100%;
            @extend %flexRow;
            justify-content: center;
            gap: 10rem;
            color: $c-text-blue;
            text-align: center;
            font-size: 18rem;
            font-weight: $fw-bold;
            line-height: 1.5;
            

            i {
                color: $c-text-blue;
            }
        }

        &__text {
            flex-basis: 100%;
            color: $c-text-blue;
            text-align: center;
            line-height: 1.5;

            a {
                font-weight: $fw-semiBold;
                color: $c-text-blue;
            }
        }

        .o-btn {
            border-color: $c-text-blue;
            color: $c-text-blue;

            &:hover {
                background: $c-text-blue;
                color: $c-white;
            }
        }

        &.support {
            background: $c-lightblue;
        }
    }

    &--faq {
        @extend %flexRow;
        gap: 0;
        background: $c-white;
        border-radius: 8rem;
        box-shadow: 0 0 6px rgba($c-black, .25);

        &__item {
            flex-basis: 100%;
            max-width: 100%;

            &-content {
                @extend %flexRow;
                gap: 24rem;
                padding: 16rem 24rem;
                border-bottom: 1rem solid $c-bg-blue;
                display: none;

                p {
                    flex-basis: 100%;
                    line-height: 1.5;
                    margin-bottom: 24rem;

                    a {
                        color: $c-blue;
                        font-weight: $fw-semiBold;
                    }
                }

                &_tutorial {
                    @extend %flexRow;
                    align-items: flex-start;                    
                    &-step {
                        flex-flow: 100%;
                        max-width: 324rem;
                        @extend %flexRow;
                        justify-content: center;
                        gap: 10rem;

                        img {}

                        h4{
                            text-align: center;
                            line-height: 1.5;
                            font-weight: $fw-semiBold;
                        }
                    }
                }
            }
            
            &-title {
                @extend %flexRow;
                padding: 8rem 16rem;
                cursor: pointer;
                
                &:not(:last-of-type) {
                    border-bottom: 1rem solid $c-bg-blue;
                }

                h3 {
                    font-size: 16rem;
                    font-weight: $fw-bold;
                    line-height: 1;
                }

                i {
                    transition: .2s all ease-in-out;
                    
                    &.opened {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &--tutorial {
        @extend %flexRow;
        gap: 24rem;

        &__video {
            max-height: 80vh;
            max-width: 100%;
            width: 840rem;
            margin: 0 auto;
            border-radius: 8rem;
            box-shadow: 0 0 6px rgba($c-black, .25);
        }

        .o-card {
            padding: 40rem;
        }
        &__thumb {
            flex-basis: 100%;
            @extend %flexRow;
            justify-content: center;

            img {
                max-height: 130rem;
            }
        }

        &__content {
            @extend %flexRow;
            gap: 10rem;
            justify-content: center;
            
            &--title {
                text-align: center;
                font-size: 20rem;
                font-weight: $fw-semiBold;
                line-height: 1.5;
            }

            &--text {
                text-align: center;
                font-size: 16rem;
                line-height: 1.5;

                a {
                    color: $c-blue;
                    font-weight: $fw-semiBold;
                    text-decoration: underline;  
                }

                strong {
                    font-weight: $fw-semiBold;
                }
            }

        }
    }

    &--files {
        .js-validated {
            display: none;

            &.formActive {
                flex-basis: 100%;
                @extend %flexRow;
            }
        }

        .formInactive {
            display: none;
        }

        &__form {
            @extend %flexRow;

            & > * {
                flex-basis: 100%;
            }

            &-row {
                flex-basis: 100%;
                @extend %flexRow;
                gap: 30rem;
                padding: 8rem 0;
                border-bottom: 1rem solid $c-bg-blue;

                @media screen and (max-width: 768px) {
                    & > * {
                        flex: 1 !important;
                    }
                }
                
                
            }

            &-label,
            &-filename,
            &-datetime {
                font-weight: $fw-semiBold;
            }

            &-status {
                display: none;
                &.success {
                    i {
                        color: $c-blue;
                    }
                }

                &.error {
                    i {
                        color: $c-red;
                    }
                }
            }
            
            &-upload {
                position: relative;
                flex-basis: 100rem;
            }

            &-label {
                flex-basis: 240rem;
            }

            &-filename {
                flex: 1;
            }

            &-datetime {}

            &--select {
                justify-content: flex-start;
                gap: 15rem;
                
                .o-form {
                    &--label {
                        flex: unset;
                        line-height: 1;
                    }

                    &--input__select {
                        flex-basis: max-content;
                    }
                }
            }    
        }
    }

    &--grid {
        &__table {
            width: 100%;
            border-spacing:0; /* Removes the cell spacing via CSS */
            border-collapse: collapse; 
            // @extend %flexRow;

            thead, tfoot {
                background: $c-lightblue;

                td {
                    font-weight: $fw-semiBold;
                }
            }

            tbody {
                background: $c-white;
            }

            th {
                font-weight: $fw-semiBold;
            }

            tr {
                border-bottom: 1rem solid $c-bg-lightblue;;
            }
            

            th, td {
                padding: 16rem;
                margin: 0 8rem;
                bottom: 0;

                &.left {
                    text-align: left;
                }

                input[type="checkbox"] {
                    @include checkbox();
                }
                
                & > i {
                    font-size: 18rem;
                    margin-left: 4rem;
                }
                //flex: 1;
                text-align: center;
                white-space: nowrap;

                &.c-content--grid__table {
                    &--min {
                        // flex: 0;
                        // flex-basis: 80rem;
                    }

                    &--action {
                        width: 65rem;
                        // flex: 0;
                        // flex-basis: 65rem;
                    }
                }
            }
        }

        &__pagination {
            @extend %flexRow;
            justify-content: flex-end;
            gap: 6rem;
            flex-basis: 100%;
            flex: 1;
            padding: 8rem 16rem;
            background: $c-bg-lightblue;

            &-button {
                background: transparent;
                border: none;
                outline: 0;
                font-size: 13rem;
                line-height: 16rem;
                font-weight: $fw-semiBold;
                color: $c-text;
                width: 24rem;
                height: 24rem;
                padding: 4rem;

                &.active {
                    color: $c-blue;
                }

                i {
                    font-size: 16rem;
                    line-height: 1;
                }
            }
        }
    }

    &--history {
        .o-card {
            flex-basis: 100%;
        }
    }
}