// CORES
$c-bg: #F0F4F7;
$c-title: #444444;
$c-text: #6a6a6a;
$c-placeholder: #999999;
$c-white: #ffffff;
$c-black: #000000;
$c-blue: #3899EC;
$c-lightblue: #DFE9F1;
$c-red: #95231E;
$c-bg-blue: #E3ECFF;
$c-bg-lightblue: #E7F0F8;
$c-text-blue: #13398D;
$c-green: #38ECE1;


//Font-weight
$fw-light: 300;
$fw-regular: 400;
$fw-semiBold: 600;
$fw-bold: 700;
$fw-extraBold: 800;

// css checkbox mixin
@mixin checkbox(
        $size: '14rem',
        $selectedColor: $c-blue,
        $checkColor: $c-white,
        $valign: 'middle',
        $rounded: rounded
    ) {
	display: none;

	&:checked + i {
		background: #{$selectedColor};
		border-color: #{$selectedColor};

		&:hover:after { opacity: 1; }

		&:after {
			opacity: 1;
      border-color: #{$checkColor};
		}
	}

  + i {
    box-sizing: border-box;
    &:before, &:after {
       box-sizing: border-box;
    }
  }
	+ i {
		position: relative;
		display: inline-block;
		width: #{$size};
		height: #{$size};
		margin: 0;
    vertical-align: #{$valign};
		border: 1rem solid $c-placeholder;
    transition: background 200ms ease;

    @if $rounded == "circle" {
      border-radius: 14rem; 
    } @else if $rounded == "rounded" {
      border-radius: 4rem; 
    } @else {
      border-radius: 0; 
    }    
     
		background: $c-white;
		font-size: #{$size};
		cursor: pointer;

		&:hover:after { opacity: .2; }

		&:after {
			content: '';
			opacity: 0;
			transform: translateZ(1rem) rotate(-45deg);
            outline: 1rem solid transparent; // hack to prevent antialias atrifacts in firefox
			position: absolute;
			top: 2.3rem;
			left: 1.5rem;
			width: .68em;
			height: .4em;
			border: .15em solid #222;
			border-top: none;
			border-right: none;
			background: rgba(0, 0, 0, 0);
		}
	}
}