@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot?v1.0.0');
    src:  url('../fonts/icomoon/icomoon.eot?v1.0.0#iefix') format('embedded-opentype'),
      url('../fonts/icomoon/icomoon.ttf?v1.0.0') format('truetype'),
      url('../fonts/icomoon/icomoon.woff?v1.0.0') format('woff'),
      url('../fonts/icomoon/icomoon.svg?v1.0.0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24rem;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-pdf:before {
    content: "\ea65";
  }
  
  .icon-abc:before {
    content: "\e900";
  }
  .icon-access-alarm:before {
    content: "\e901";
  }
  .icon-accessibility-new:before {
    content: "\e902";
  }
  .icon-accessible-forward:before {
    content: "\e903";
  }
  .icon-access-time:before {
    content: "\e904";
  }
  .icon-account-balance:before {
    content: "\e905";
  }
  .icon-account-balance-wallet:before {
    content: "\e906";
  }
  .icon-account-circle:before {
    content: "\e907";
  }
  .icon-add:before {
    content: "\e908";
  }
  .icon-admin-panel-settings:before {
    content: "\e909";
  }
  .icon-airplanemode-active:before {
    content: "\e90a";
  }
  .icon-airplanemode-inactive:before {
    content: "\e90b";
  }
  .icon-alarm:before {
    content: "\e90c";
  }
  .icon-alternate-email:before {
    content: "\e90d";
  }
  .icon-anchor:before {
    content: "\e90e";
  }
  .icon-api:before {
    content: "\e90f";
  }
  .icon-apple:before {
    content: "\e910";
  }
  .icon-arrow-back:before {
    content: "\e911";
  }
  .icon-arrow-back-ios:before {
    content: "\e912";
  }
  .icon-arrow-back-ios-new:before {
    content: "\e913";
  }
  .icon-arrow-downward:before {
    content: "\e914";
  }
  .icon-arrow-drop-down:before {
    content: "\e915";
  }
  .icon-arrow-drop-up:before {
    content: "\e916";
  }
  .icon-arrow-forward:before {
    content: "\e917";
  }
  .icon-arrow-forward-ios:before {
    content: "\e918";
  }
  .icon-arrow-left:before {
    content: "\e919";
  }
  .icon-arrow-right:before {
    content: "\e91a";
  }
  .icon-assignment:before {
    content: "\e91b";
  }
  .icon-assistant:before {
    content: "\e91c";
  }
  .icon-assistant-photo:before {
    content: "\e91d";
  }
  .icon-attach-file:before {
    content: "\e91e";
  }
  .icon-attach-money:before {
    content: "\e91f";
  }
  .icon-auto-awesome:before {
    content: "\e920";
  }
  .icon-auto-fix-high:before {
    content: "\e921";
  }
  .icon-backspace:before {
    content: "\e922";
  }
  .icon-backup:before {
    content: "\e923";
  }
  .icon-balance:before {
    content: "\e924";
  }
  .icon-bar-chart:before {
    content: "\e925";
  }
  .icon-bar-code:before {
    content: "\e926";
  }
  .icon-beach-access:before {
    content: "\e927";
  }
  .icon-block:before {
    content: "\e928";
  }
  .icon-bluetooth:before {
    content: "\e929";
  }
  .icon-bolt:before {
    content: "\e92a";
  }
  .icon-bookmark:before {
    content: "\e92b";
  }
  .icon-bookmark-border:before {
    content: "\e92c";
  }
  .icon-bookmarks:before {
    content: "\e92d";
  }
  .icon-bug-report:before {
    content: "\e92e";
  }
  .icon-cached:before {
    content: "\e92f";
  }
  .icon-calendar-today:before {
    content: "\e930";
  }
  .icon-call:before {
    content: "\e931";
  }
  .icon-camera-alt:before {
    content: "\e932";
  }
  .icon-cancel:before {
    content: "\e933";
  }
  .icon-chat:before {
    content: "\e934";
  }
  .icon-chat-bubble:before {
    content: "\e935";
  }
  .icon-check:before {
    content: "\e936";
  }
  .icon-check-box:before {
    content: "\e937";
  }
  .icon-check-box-outline-blank:before {
    content: "\e938";
  }
  .icon-check-circle:before {
    content: "\e939";
  }
  .icon-checklist:before {
    content: "\e93a";
  }
  .icon-checkroom:before {
    content: "\e93b";
  }
  .icon-chevron-left:before {
    content: "\e93c";
  }
  .icon-chevron-right:before {
    content: "\e93d";
  }
  .icon-clear-all:before {
    content: "\e93e";
  }
  .icon-close:before {
    content: "\e93f";
  }
  .icon-close-fullscreen:before {
    content: "\e940";
  }
  .icon-cloud-queue:before {
    content: "\e941";
  }
  .icon-cloud-upload:before {
    content: "\e942";
  }
  .icon-code:before {
    content: "\e943";
  }
  .icon-color-lens:before {
    content: "\e944";
  }
  .icon-commit:before {
    content: "\e945";
  }
  .icon-computer-bug:before {
    content: "\e946";
  }
  .icon-contact-support:before {
    content: "\e947";
  }
  .icon-content-copy:before {
    content: "\e948";
  }
  .icon-content-cut:before {
    content: "\e949";
  }
  .icon-content-paste:before {
    content: "\e94a";
  }
  .icon-coronavirus:before {
    content: "\e94b";
  }
  .icon-create:before {
    content: "\e94c";
  }
  .icon-create-new-folder:before {
    content: "\e94d";
  }
  .icon-credit-card:before {
    content: "\e94e";
  }
  .icon-crop-free:before {
    content: "\e94f";
  }
  .icon-currency-bitcoin:before {
    content: "\e950";
  }
  .icon-currency-pound:before {
    content: "\e951";
  }
  .icon-currency-yen:before {
    content: "\e952";
  }
  .icon-dangerous:before {
    content: "\e953";
  }
  .icon-dashboard:before {
    content: "\e954";
  }
  .icon-data-object:before {
    content: "\e955";
  }
  .icon-date-range:before {
    content: "\e956";
  }
  .icon-delete:before {
    content: "\e957";
  }
  .icon-delete-outline:before {
    content: "\e958";
  }
  .icon-description:before {
    content: "\e959";
  }
  .icon-desktop-mac:before {
    content: "\e95a";
  }
  .icon-desktop-windows:before {
    content: "\e95b";
  }
  .icon-dialpad:before {
    content: "\e95c";
  }
  .icon-discord:before {
    content: "\e95d";
  }
  .icon-done:before {
    content: "\e95e";
  }
  .icon-done-all:before {
    content: "\e95f";
  }
  .icon-download:before {
    content: "\e960";
  }
  .icon-download-done:before {
    content: "\e961";
  }
  .icon-drafts:before {
    content: "\e962";
  }
  .icon-drag-handle:before {
    content: "\e963";
  }
  .icon-drag-indicator:before {
    content: "\e964";
  }
  .icon-east:before {
    content: "\e965";
  }
  .icon-emoji-events:before {
    content: "\e966";
  }
  .icon-emoji-nature:before {
    content: "\e967";
  }
  .icon-error:before {
    content: "\e968";
  }
  .icon-event:before {
    content: "\e969";
  }
  .icon-expand-less:before {
    content: "\e96a";
  }
  .icon-expand-more:before {
    content: "\e96b";
  }
  .icon-explore:before {
    content: "\e96c";
  }
  .icon-extension:before {
    content: "\e96d";
  }
  .icon-face-2:before {
    content: "\e96e";
  }
  .icon-face-3:before {
    content: "\e96f";
  }
  .icon-face-4:before {
    content: "\e970";
  }
  .icon-face-5:before {
    content: "\e971";
  }
  .icon-face-6:before {
    content: "\e972";
  }
  .icon-facebook:before {
    content: "\e973";
  }
  .icon-fast-forward:before {
    content: "\e974";
  }
  .icon-fast-rewind:before {
    content: "\e975";
  }
  .icon-favorite:before {
    content: "\e976";
  }
  .icon-favorite-border:before {
    content: "\e977";
  }
  .icon-fax:before {
    content: "\e978";
  }
  .icon-feedback:before {
    content: "\e979";
  }
  .icon-file-copy:before {
    content: "\e97a";
  }
  .icon-file-present:before {
    content: "\e97b";
  }
  .icon-filter-alt:before {
    content: "\e97c";
  }
  .icon-filter-alt-off:before {
    content: "\e97d";
  }
  .icon-filter-list:before {
    content: "\e97e";
  }
  .icon-fingerprint:before {
    content: "\e97f";
  }
  .icon-first-page:before {
    content: "\e980";
  }
  .icon-flag:before {
    content: "\e981";
  }
  .icon-folder:before {
    content: "\e982";
  }
  .icon-folder-off:before {
    content: "\e983";
  }
  .icon-folder-shared:before {
    content: "\e984";
  }
  .icon-format-align-center:before {
    content: "\e985";
  }
  .icon-format-align-justify:before {
    content: "\e986";
  }
  .icon-format-align-left:before {
    content: "\e987";
  }
  .icon-format-align-right:before {
    content: "\e988";
  }
  .icon-format-bold:before {
    content: "\e989";
  }
  .icon-format-clear:before {
    content: "\e98a";
  }
  .icon-format-color-fill:before {
    content: "\e98b";
  }
  .icon-format-color-reset:before {
    content: "\e98c";
  }
  .icon-format-indent-decrease:before {
    content: "\e98d";
  }
  .icon-format-indent-increase:before {
    content: "\e98e";
  }
  .icon-format-italic:before {
    content: "\e98f";
  }
  .icon-format-list-bulleted:before {
    content: "\e990";
  }
  .icon-format-list-numbered:before {
    content: "\e991";
  }
  .icon-forward:before {
    content: "\e992";
  }
  .icon-fullscreen:before {
    content: "\e993";
  }
  .icon-fullscreen-exit:before {
    content: "\e994";
  }
  .icon-functions:before {
    content: "\e995";
  }
  .icon-group:before {
    content: "\e996";
  }
  .icon-group-add:before {
    content: "\e997";
  }
  .icon-group-off:before {
    content: "\e998";
  }
  .icon-group-remove:before {
    content: "\e999";
  }
  .icon-groups:before {
    content: "\e99a";
  }
  .icon-group-work:before {
    content: "\e99b";
  }
  .icon-headphones:before {
    content: "\e99c";
  }
  .icon-health-and-safety:before {
    content: "\e99d";
  }
  .icon-heart-broken:before {
    content: "\e99e";
  }
  .icon-help:before {
    content: "\e99f";
  }
  .icon-hide-image:before {
    content: "\e9a0";
  }
  .icon-history:before {
    content: "\e9a1";
  }
  .icon-how-to-reg:before {
    content: "\e9a2";
  }
  .icon-image:before {
    content: "\e9a3";
  }
  .icon-indeterminate-check-box:before {
    content: "\e9a4";
  }
  .icon-info:before {
    content: "\e9a5";
  }
  .icon-insert-drive-file:before {
    content: "\e9a6";
  }
  .icon-insights:before {
    content: "\e9a7";
  }
  .icon-keyboard:before {
    content: "\e9a8";
  }
  .icon-keyboard-arrow-down:before {
    content: "\e9a9";
  }
  .icon-keyboard-arrow-left:before {
    content: "\e9aa";
  }
  .icon-keyboard-arrow-right:before {
    content: "\e9ab";
  }
  .icon-keyboard-arrow-up:before {
    content: "\e9ac";
  }
  .icon-keyboard-backspace:before {
    content: "\e9ad";
  }
  .icon-keyboard-control-key:before {
    content: "\e9ae";
  }
  .icon-keyboard-double-arrow-down:before {
    content: "\e9af";
  }
  .icon-keyboard-double-arrow-left:before {
    content: "\e9b0";
  }
  .icon-keyboard-double-arrow-right:before {
    content: "\e9b1";
  }
  .icon-keyboard-double-arrow-up:before {
    content: "\e9b2";
  }
  .icon-keyboard-voice:before {
    content: "\e9b3";
  }
  .icon-label:before {
    content: "\e9b4";
  }
  .icon-label-off:before {
    content: "\e9b5";
  }
  .icon-language:before {
    content: "\e9b6";
  }
  .icon-laptop:before {
    content: "\e9b7";
  }
  .icon-laptop-chromebook:before {
    content: "\e9b8";
  }
  .icon-laptop-mac:before {
    content: "\e9b9";
  }
  .icon-laptop-windows:before {
    content: "\e9ba";
  }
  .icon-last-page:before {
    content: "\e9bb";
  }
  .icon-launch:before {
    content: "\e9bc";
  }
  .icon-leaderboard:before {
    content: "\e9bd";
  }
  .icon-library-add:before {
    content: "\e9be";
  }
  .icon-library-add-check:before {
    content: "\e9bf";
  }
  .icon-library-books:before {
    content: "\e9c0";
  }
  .icon-library-music:before {
    content: "\e9c1";
  }
  .icon-lightbulb:before {
    content: "\e9c2";
  }
  .icon-link:before {
    content: "\e9c3";
  }
  .icon-link-off:before {
    content: "\e9c4";
  }
  .icon-list:before {
    content: "\e9c5";
  }
  .icon-location-off:before {
    content: "\e9c6";
  }
  .icon-location-on:before {
    content: "\e9c7";
  }
  .icon-lock:before {
    content: "\e9c8";
  }
  .icon-lock-open:before {
    content: "\e9c9";
  }
  .icon-login:before {
    content: "\e9ca";
  }
  .icon-logout:before {
    content: "\e9cb";
  }
  .icon-mail:before {
    content: "\e9cc";
  }
  .icon-mark-chat-read:before {
    content: "\e9cd";
  }
  .icon-mark-chat-unread:before {
    content: "\e9ce";
  }
  .icon-memory:before {
    content: "\e9cf";
  }
  .icon-message:before {
    content: "\e9d0";
  }
  .icon-mic:before {
    content: "\e9d1";
  }
  .icon-mic-none:before {
    content: "\e9d2";
  }
  .icon-mic-off:before {
    content: "\e9d3";
  }
  .icon-minimize:before {
    content: "\e9d4";
  }
  .icon-mode-edit:before {
    content: "\e9d5";
  }
  .icon-mode-night:before {
    content: "\e9d6";
  }
  .icon-monitor:before {
    content: "\e9d7";
  }
  .icon-mood:before {
    content: "\e9d8";
  }
  .icon-mood-bad:before {
    content: "\e9d9";
  }
  .icon-more-horiz:before {
    content: "\e9da";
  }
  .icon-more-vert:before {
    content: "\e9db";
  }
  .icon-mouse:before {
    content: "\e9dc";
  }
  .icon-movie:before {
    content: "\e9dd";
  }
  .icon-navigate-before:before {
    content: "\e9de";
  }
  .icon-navigate-next:before {
    content: "\e9df";
  }
  .icon-north:before {
    content: "\e9e0";
  }
  .icon-north-east:before {
    content: "\e9e1";
  }
  .icon-north-west:before {
    content: "\e9e2";
  }
  .icon-note:before {
    content: "\e9e3";
  }
  .icon-not-interested:before {
    content: "\e9e4";
  }
  .icon-opacity:before {
    content: "\e9e5";
  }
  .icon-open-with:before {
    content: "\e9e6";
  }
  .icon-outbox:before {
    content: "\e9e7";
  }
  .icon-palette:before {
    content: "\e9e8";
  }
  .icon-pan-tool:before {
    content: "\e9e9";
  }
  .icon-pan-tool-alt:before {
    content: "\e9ea";
  }
  .icon-pause:before {
    content: "\e9eb";
  }
  .icon-payment:before {
    content: "\e9ec";
  }
  .icon-paypal:before {
    content: "\e9ed";
  }
  .icon-pendent:before {
    content: "\e9ee";
  }
  .icon-percent:before {
    content: "\e9ef";
  }
  .icon-perm-identity:before {
    content: "\e9f0";
  }
  .icon-person:before {
    content: "\e9f1";
  }
  .icon-person-2:before {
    content: "\e9f2";
  }
  .icon-person-3:before {
    content: "\e9f3";
  }
  .icon-person-4:before {
    content: "\e9f4";
  }
  .icon-person-add-alt-1:before {
    content: "\e9f5";
  }
  .icon-person-remove-alt-1:before {
    content: "\e9f6";
  }
  .icon-pets:before {
    content: "\e9f7";
  }
  .icon-phone:before {
    content: "\e9f8";
  }
  .icon-phone-android:before {
    content: "\e9f9";
  }
  .icon-phone-iphone:before {
    content: "\e9fa";
  }
  .icon-photo:before {
    content: "\e9fb";
  }
  .icon-photo-camera:before {
    content: "\e9fc";
  }
  .icon-piano:before {
    content: "\e9fd";
  }
  .icon-pie-chart:before {
    content: "\e9fe";
  }
  .icon-pin-off:before {
    content: "\e9ff";
  }
  .icon-play-arrow:before {
    content: "\ea00";
  }
  .icon-power:before {
    content: "\ea01";
  }
  .icon-power-settings-new:before {
    content: "\ea02";
  }
  .icon-pregnant-woman:before {
    content: "\ea03";
  }
  .icon-preview:before {
    content: "\ea04";
  }
  .icon-print:before {
    content: "\ea05";
  }
  .icon-privacy-tip:before {
    content: "\ea06";
  }
  .icon-public:before {
    content: "\ea07";
  }
  .icon-public-off:before {
    content: "\ea08";
  }
  .icon-publish:before {
    content: "\ea09";
  }
  .icon-push-pin:before {
    content: "\ea0a";
  }
  .icon-query-builder:before {
    content: "\ea0b";
  }
  .icon-question-answer:before {
    content: "\ea0c";
  }
  .icon-question-mark:before {
    content: "\ea0d";
  }
  .icon-radio-button-checked:before {
    content: "\ea0e";
  }
  .icon-radio-button-unchecked:before {
    content: "\ea0f";
  }
  .icon-receipt:before {
    content: "\ea10";
  }
  .icon-recycling:before {
    content: "\ea11";
  }
  .icon-redo:before {
    content: "\ea12";
  }
  .icon-refresh:before {
    content: "\ea13";
  }
  .icon-remove:before {
    content: "\ea14";
  }
  .icon-remove-red-eye:before {
    content: "\ea15";
  }
  .icon-reorder:before {
    content: "\ea16";
  }
  .icon-replay:before {
    content: "\ea17";
  }
  .icon-reply:before {
    content: "\ea18";
  }
  .icon-reply-all:before {
    content: "\ea19";
  }
  .icon-report:before {
    content: "\ea1a";
  }
  .icon-rocket-launch:before {
    content: "\ea1b";
  }
  .icon-room:before {
    content: "\ea1c";
  }
  .icon-save:before {
    content: "\ea1d";
  }
  .icon-save-all:before {
    content: "\ea1e";
  }
  .icon-schedule:before {
    content: "\ea1f";
  }
  .icon-search:before {
    content: "\ea20";
  }
  .icon-security:before {
    content: "\ea21";
  }
  .icon-send:before {
    content: "\ea22";
  }
  .icon-settings:before {
    content: "\ea23";
  }
  .icon-settings-backup-restore:before {
    content: "\ea24";
  }
  .icon-share:before {
    content: "\ea25";
  }
  .icon-shield:before {
    content: "\ea26";
  }
  .icon-shopify:before {
    content: "\ea27";
  }
  .icon-shopping-bag:before {
    content: "\ea28";
  }
  .icon-shopping-cart:before {
    content: "\ea29";
  }
  .icon-shortcut:before {
    content: "\ea2a";
  }
  .icon-show-chart:before {
    content: "\ea2b";
  }
  .icon-shuffle:before {
    content: "\ea2c";
  }
  .icon-skip-next:before {
    content: "\ea2d";
  }
  .icon-smart-display:before {
    content: "\ea2e";
  }
  .icon-smartphone:before {
    content: "\ea2f";
  }
  .icon-sort:before {
    content: "\ea30";
  }
  .icon-sort-by-alpha:before {
    content: "\ea31";
  }
  .icon-south:before {
    content: "\ea32";
  }
  .icon-south-east:before {
    content: "\ea33";
  }
  .icon-south-west:before {
    content: "\ea34";
  }
  .icon-speaker:before {
    content: "\ea35";
  }
  .icon-speed:before {
    content: "\ea36";
  }
  .icon-star:before {
    content: "\ea37";
  }
  .icon-star-border:before {
    content: "\ea38";
  }
  .icon-star-half:before {
    content: "\ea39";
  }
  .icon-stop:before {
    content: "\ea3a";
  }
  .icon-storage:before {
    content: "\ea3b";
  }
  .icon-store:before {
    content: "\ea3c";
  }
  .icon-subdirectory-arrow-left:before {
    content: "\ea3d";
  }
  .icon-subdirectory-arrow-right:before {
    content: "\ea3e";
  }
  .icon-subject:before {
    content: "\ea3f";
  }
  .icon-supervisor-account:before {
    content: "\ea40";
  }
  .icon-swap-horiz:before {
    content: "\ea41";
  }
  .icon-swap-vert:before {
    content: "\ea42";
  }
  .icon-table-chart:before {
    content: "\ea43";
  }
  .icon-table-rows:before {
    content: "\ea44";
  }
  .icon-tablet-android:before {
    content: "\ea45";
  }
  .icon-tablet-mac:before {
    content: "\ea46";
  }
  .icon-text-format:before {
    content: "\ea47";
  }
  .icon-thumb-down:before {
    content: "\ea48";
  }
  .icon-thumb-up:before {
    content: "\ea49";
  }
  .icon-toggle-off:before {
    content: "\ea4a";
  }
  .icon-toggle-on:before {
    content: "\ea4b";
  }
  .icon-token:before {
    content: "\ea4c";
  }
  .icon-trending-down:before {
    content: "\ea4d";
  }
  .icon-trending-flat:before {
    content: "\ea4e";
  }
  .icon-trending-up:before {
    content: "\ea4f";
  }
  .icon-tv:before {
    content: "\ea50";
  }
  .icon-unfold-less:before {
    content: "\ea51";
  }
  .icon-unfold-more:before {
    content: "\ea52";
  }
  .icon-videocam:before {
    content: "\ea53";
  }
  .icon-videocam-off:before {
    content: "\ea54";
  }
  .icon-visibility:before {
    content: "\ea55";
  }
  .icon-visibility-off:before {
    content: "\ea56";
  }
  .icon-voicemail:before {
    content: "\ea57";
  }
  .icon-volume-down:before {
    content: "\ea58";
  }
  .icon-volume-mute:before {
    content: "\ea59";
  }
  .icon-volume-off:before {
    content: "\ea5a";
  }
  .icon-volume-up:before {
    content: "\ea5b";
  }
  .icon-vpn-key:before {
    content: "\ea5c";
  }
  .icon-vpn-key-off:before {
    content: "\ea5d";
  }
  .icon-wallpaper:before {
    content: "\ea5e";
  }
  .icon-warning:before {
    content: "\ea5f";
  }
  .icon-watch:before {
    content: "\ea60";
  }
  .icon-west:before {
    content: "\ea61";
  }
  .icon-widgets:before {
    content: "\ea62";
  }
  .icon-wordpress:before {
    content: "\ea63";
  }
  .icon-work:before {
    content: "\ea64";
  }
  